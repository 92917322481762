<template>
    <v-dialog v-model="showDialog">
        <v-card>
            <v-card-title>
                <span>Exportar Ordenes</span>
            </v-card-title>
            <v-card-text>
               
                <div class="row" >
                    <div class="col-md-4" >
                         <v-alert border="top" colored-border type="info" elevation="1" >
                            <v-alert class="mt-4" color="white" >
                                Se exportartan <b>los primeros 1000 registros</b> a partir de la fecha seleccionada.
                                <v-menu ref="menu" v-model="menu" :close-on-content-click="true" :return-value.sync="date" transition="scale-transition" offset-y min-width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field 
                                            v-model="dateText" label="Fecha desde" prepend-icon="event" readonly v-bind="attrs" v-on="on" :rules="[v => !!v || 'Debes completar este campo']" 
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker v-model="date" scrollable @input="formatearRango"></v-date-picker>
                                </v-menu>
                                <v-btn class="mr-2 mb-2 " dark color="success" :disabled="!readyFor || loading" @click="exportExcelByConf" >Exportar en XLSX</v-btn>
                                <v-btn class="mr-2 mb-2 " dark color="success" :disabled="!readyFor || loading" @click="exportCSVByConf" >Exportar en CSV</v-btn>
                            </v-alert>
                        </v-alert>
                        
                    </div>
                    <div class="col-md-4" >
                        <v-alert border="top" colored-border type="warning" elevation="1" >
                            <v-alert class="mt-4" color="white" >Se envía por mail in link para acceder al archivo con <b>todos los registros</b>.</v-alert>
                             <v-btn class="mr-2 mb-2 " dark color="success" @click="exportExcelAllByConf" >Exportar en XLSX</v-btn>
                             <v-btn class="mr-2 mb-2 " dark color="success" @click="exportCSVAllByConf" >Exportar en CSV</v-btn>
                        </v-alert>
                    </div>
                 
               
              
                   <div class="col-md-4" >
                        <v-alert border="top" colored-border type="info" elevation="1" >
                            <v-alert class="mt-4 " color="white" > Se exportan los<b> {{itemsPerPage}} registros de la p&aacute;gina actual.</b></br><small>Puedes cambiar la cantidad en la opción "Registros por página" en la lista de pedidos</small></v-alert>
                            <v-btn class="mr-2 mb-2 "  dark color="success" @click="exportExcel" >Exportar en XLSX</v-btn>
                             <v-btn class="mr-2 mb-2 " dark color="success" @click="exportCSV" >Exportar en CSV</v-btn>
                       </v-alert>
                       
                   </div>
               </div>
      
                
                <div class="row" >
                   
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn text color="blue darken-1" @click="showDialog = false">Cancelar</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            date: "",
            loading: false,
            dateText: '',
            menu: false,
            itemsPerPage: 100,
        }
    },
    created(){
        this.$parent.$on('open-exportar-ordenes-dialog', () => {
            this.showDialog = true;
            this.itemsPerPage = this.$parent.selectItemsPerPage;
        });
    },
    methods:{
        exportExcelByConf(){
            var vm = this;
            this.axios({
                url: 'orders/export_with_configuration/excel?date='+this.dateText+'&timezone='+this.$store.state.passport.timezone,
                method: 'GET',
                responseType: "blob",
            }).then( response => {
                const newBlob = new Blob([response.data], {
                type:
                    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data, "_blank");
                this.showDialog = false;
            }).catch( error => {
                console.log(error);
                vm.abrirMensajeError("Sin registros para exportar, verifique la fecha ingresada.");
            });
        },
        exportCSVByConf(){
            var vm = this;
            this.axios({
                url: 'orders/export_with_configuration/csv?date='+this.dateText+'&timezone='+this.$store.state.passport.timezone,
                method: 'GET',
                responseType: "blob",
            }).then( response => {
                const newBlob = new Blob([response.data], {
                type:
                    "text/csv;charset=utf-8;",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data, "_blank");
                this.showDialog = false;
            }).catch( error => {
                console.log(error);
                vm.abrirMensajeError("Sin registros para exportar, verifique la fecha ingresada.");
            });
        },
        exportExcelAllByConf(){
            var vm = this;
            this.axios({
                url: 'orders/export_all_with_configuration/excel?timezone='+this.$store.state.passport.timezone, method: 'GET',
            }).then( response => {
                vm.abrirMensajeSuccess("En minutos recibirá por mail el link para acceder al recurso.");
            }).catch( error => {
                console.log(error);
            });
        },
        exportCSVAllByConf(){
            var vm = this;
            this.axios({
                url: 'orders/export_all_with_configuration/csv?timezone='+this.$store.state.passport.timezone, method: 'GET',
            }).then( response => {
                vm.abrirMensajeSuccess("En minutos recibirá por mail el link para acceder al recurso.");
            }).catch( error => {
                console.log(error);
            });
        },
        formatearRango()
        {
            if(!this.date.length){
                this.dateText = '';
            }else{
                this.dateText = this.date;
            }
        },
        abrirMensajeSuccess(mensaje) {
            this.$bvToast.toast(mensaje, { title: `Información`, variant: "success", solid: true, toaster: "b-toaster-bottom-center" });
            this.fetchData();
        },
        abrirMensajeError(mensaje) {
            this.$bvToast.toast(mensaje, {
                title: `Error`,
                variant: "danger",
                solid: true,
                toaster: "b-toaster-bottom-center",
            });
        },
        exportExcel() {
            var headers = [
                "id",
                "type",
                "reference",
                "tracking",
                "marketplace",
                "shipping",
                "date",
                "estado",
                "sub_estado",
                "sku",
                "name",
                "quantity",
                "total",
            ];
            var mapeado = [];
            this.$parent.tableData.forEach((order) => {
                if (order.order_details.length) {
                    order.order_details.forEach((product) => {
                        mapeado.push({
                            id: order.id,
                            type: order.order_type,
                            reference: order.order_reference,
                            tracking: order.shipping_number,
                            marketplace: order.source,
                            shipping: order.shipping_number,
                            date: order.source_date,
                            estado: order.name_order_state,
                            sub_estado: (order.name_order_sub_state ? order.name_order_sub_state : ""),
                            sku: product.sku,
                            name: product.product,
                            quantity: product.quantity,
                            total: product.total,
                        });
                    });
                }
            });
            this.axios({ 
                url: "excel/from_array", 
                method: "POST", 
                data: { 
                    data: mapeado, 
                    cabeceras: headers
                }, 
                responseType: "blob", 
            })
            .then((response) => {
                const newBlob = new Blob([response.data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data, "_blank");
            })
            .catch((error) => {
                console.log(error);
                vm.abrirMensajeError("Sin registros para exportar.");
            });
        },
        exportCSV(){
            var vm = this;
            var headers = [
                "id",
                "type",
                "reference",
                "tracking",
                "marketplace",
                "shipping",
                "date",
                "estado",
                "sub_estado",
                "sku",
                "name",
                "quantity",
                "total",
            ];
            var mapeado = [];
            this.$parent.tableData.forEach((order) => {
                if (order.order_details.length) {
                    order.order_details.forEach((product) => {
                        mapeado.push({
                            id: order.id,
                            type: order.order_type,
                            reference: order.order_reference,
                            tracking: order.shipping_number,
                            marketplace: order.source,
                            shipping: order.shipping_number,
                            date: order.source_date,
                            estado: order.name_order_state,
                            sub_estado: (order.name_order_sub_state ? name_order_sub_state : ""),
                            sku: product.sku,
                            name: product.product,
                            quantity: product.quantity,
                            total: product.total,
                        });
                    });
                }
            });
            this.axios({
                url: 'csv/from_array', method: 'POST', 
                data: { 
                    data: mapeado, 
                    cabeceras: headers
                }, 
                responseType: "blob",
            }).then( response => {
                const newBlob = new Blob([response.data], {
                    type: "text/csv;charset=utf-8;",
                });
                const data = window.URL.createObjectURL(newBlob);
                window.open(data, "_blank");
                this.showDialog = false;
            }).catch( error => {
                console.log(error);
                vm.abrirMensajeError("Sin registros para exportar.");
            });
        },
    },
    computed:{
        readyFor(){
            return this.dateText != "" ? true : false;
        }
    }
}
</script>