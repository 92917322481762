<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="1000px">
        <v-card v-if="showDialog">
            <v-card-title>
                <span>Pedido #{{orden_id}}</span>
            </v-card-title>
            <v-card-text>
                <div class="row" v-if="archive">
                    <div class="col pb-0 pt-0 mb-0 mt-0" style="height: 3rem">
                        <v-checkbox v-model="validArchiveCheck1" label="Al archivar una orden NO CANCELADA, el sistema CANCELARÁ la orden antes de archivarla."></v-checkbox>
                    </div>                    
                </div>
                <div class="row" v-if="archive">
                    <div class="col pb-0 pt-0 mb-0 mt-0" style="height: 3rem">
                        <v-checkbox v-model="validArchiveCheck2" label="Esta acción no es reversible, una vez que presione GUARDAR la CANCELACIÓN de la orden no puede revertirse."></v-checkbox>
                    </div>                    
                </div>            
                <div class="row" v-if="archive">
                    <div class="col pb-0 pt-0 mb-3 mt-0" style="height: 3rem">
                        <v-checkbox v-model="validArchiveCheck3" label="Al CANCELAR una orden que no ha sido PREPARADA se devolverá al inventario disponible el stock de los productos de la orden."></v-checkbox>
                    </div>                    
                </div>
                <div class="row" v-show="!archive">
                    <div class="col">
                        <v-select
                            v-model="current_state"
                            :items="orderStatesList"
                            :rules="[v => !!v || 'Debes elegir un estado']"
                            label="Estado del pedido"
                            required
                            @change="handleChangeOrderStateSelect"
                        ></v-select>
                    </div>
                </div>
                <div class="row" v-show="!archive && orderSubStatesList.length">
                    <div class="col">
                        <v-select
                            v-model="current_substate"
                            :items="orderSubStatesList"
                            :rules="[v => !!v || 'Debes elegir un subestado']"
                            label="Subestado del pedido"
                            required
                        ></v-select>
                    </div>
                </div>
                <div class="row" v-if="orderValdidationState">
                    <div class="col">
                        <v-checkbox v-model="validStateChange" label="Validar en sistema Logístico?" > Validar en sistema Logístico? </v-checkbox>
                    </div>
                </div>                
            </v-card-text>
            <v-card-actions>
                <v-btn @click="showDialog = false" dark color="pink" >
                    Cancelar
                </v-btn>
                <v-btn color="success" class="btn-starkons-primary" @click="sendForm" :disabled=" disableSendFormBtn || (archive && (!validArchiveCheck1 || !validArchiveCheck2 || !validArchiveCheck3)) " >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            orden_id: undefined,
            store_id: undefined,
            current_state: undefined,
            current_substate: undefined,
            orderStatesList: [],
            orderSubStatesList: [],
            disableSendFormBtn: false,
            archive: false,
            validArchiveCheck1: false,
            validArchiveCheck2: false,
            validArchiveCheck3: false,
        }
    },
    created(){
        
        this.$parent.$on('openDialogCambiarEstadoOrden', (datos) => {
            this.orden_id = datos.order_id;
            this.store_id = datos.store_id;
            this.archive = datos.archive;
            this.current_state = datos.current_state;
            this.current_substate = datos.current_substate_name ? datos.current_substate_name : undefined;
            this.arhive = datos.archive ? datos.archive : false
            this.orderValdidationState = undefined
            this.validStateChange = 1;            
            this.validArchiveCheck1 = false;
            this.validArchiveCheck2 = false;
            this.validArchiveCheck3 = false;
            console.log('datos', datos);
            //this.current_substate = datos.
            this.fetchData();
        });
    },
    methods: {
        handleChangeOrderStateSelect(){
            const orderStateFromList = this.orderStatesList.find(e => e.value == this.current_state);
            if(orderStateFromList.substates.length){
                this.orderSubStatesList = orderStateFromList.substates;
            }else{
                this.orderSubStatesList = [];
            }
            if(!this.orderSubStatesList.find(e => e.value == this.current_substate)){
                this.current_substate = undefined;
            }
            
            console.log(orderStateFromList.additional_data.state_to_validate);
            if(orderStateFromList.additional_data.state_to_validate && orderStateFromList.additional_data.state_to_validate == 1 ){
                this.orderValdidationState = true;
            }
            else{
                this.orderValdidationState = false;
            }
            console.log('orderValdidationState');
            console.log(this.orderValdidationState);
            
        },
        sendForm(){
            var vm = this;

            vm.disableSendFormBtn = true;
            this.axios({
                url: 'orders/'+this.orden_id+'/state',
                method: 'POST',
                data: {
                    state: this.current_state,
                    substate: this.orderSubStatesList.length ? this.current_substate : null,
                    validatestatelogistic: this.orderValdidationState ? this.validStateChange : 1
                }
            }).then( () => {
                if(this.archive && this.validArchiveCheck1 && this.validArchiveCheck2 && this.validArchiveCheck3){
                    this.axios({
                        url: "orders/" + this.orden_id,
                        method: "DELETE",
                    })
                    .then((response) => {
                        vm.showDialog = false;
                        vm.$emit('success', "Pedido archivado correctamente");
                        vm.disableSendFormBtn = false;
                    })
                    .catch((error) => {
                        console.log(error);
                        vm.disableSendFormBtn = false;
                    });
                }
                else{
                    vm.showDialog = false;
                    vm.$emit('success', "Estado modificado correctamente");
                    vm.disableSendFormBtn = false;
                }
            }).catch( () => {
                //console.log(error);
                vm.disableSendFormBtn = false;
            });
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'orders/states/data_select_input?store='+this.store_id+'&order_id='+this.orden_id,
            }).then( response => {
                if(this.archive){
                    vm.orderStatesList = [];
                    response.data.forEach(e => {
                        if(e.type == "DEFAULT_CANCELED") {
                            vm.orderStatesList.push(e);
                            vm.current_state = e.value;
                        }
                    });                                    
                }
                else{
                    vm.orderStatesList = response.data;
                }                
                vm.handleChangeOrderStateSelect();                 
                vm.showDialog = true; 
                // console.log('orderStatesList', vm.orderStatesList);
            }).catch( (error) => {
                console.log(error);
            });
        }
    }
}
</script>