<template>
  <div class="dashboard_ordenes">
    <v-app>
      <v-card>
        <v-card-title>
          Ordenes en el sistema
          <v-spacer></v-spacer>
          <!--<div class="mx-4">
                    <v-btn  color="primary" fab x-small dark v-show="selected.length == 1">
                        <v-icon>mdi-pencil</v-icon>
                    </v-btn>
          </div>-->
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="showSelect = !showSelect" text icon color="blue">
                <v-icon>mdi-{{showSelect ? 'table-off' : 'table'}}</v-icon>
              </v-btn>
            </template>
            <span>{{showSelect ? 'Desactivar':'Activar'}} modo avanzado</span>
          </v-tooltip>
          <v-tooltip v-if="showSelect && selected.length > 0" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="addSelected" text icon color="blue">
                <v-icon>mdi-table-plus</v-icon>
              </v-btn>
            </template>
            <span> Agregar pedido{{selected.length > 1 ? 's':''}} a la cola</span>
          </v-tooltip>
          <v-tooltip v-if="added.length > 0 && showSelect" bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="openDialogCambiarEstadoMasivo" text icon color="blue">
                <v-icon>icon-action_updatestate</v-icon>
              </v-btn>
            </template>
            <span>Cambiar estado a cola de Pedidos</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" class @click="$emit('open-exportar-ordenes-dialog')" text icon color="green">
                <v-icon>mdi-file-excel</v-icon>
              </v-btn>
            </template>
            <span>Exportar a Excel</span>
          </v-tooltip>
          <v-btn class @click="fetchDataPaginated" text icon color="blue">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-card outlined tile elevation="1">
            <v-card-text style="padding-bottom: 0px;">
              
                <div class="d-flex justify-space-between mb-4 col-12">
                <div class="row">
                  <div class="col-12 col-sm-9">
                    
                <v-text-field
                  v-model="search"
                  clearable
                  label="Buscar"
                  hide-details
                  dense
                  solo
                  @change="detectFilterChange"
                  class="mr-2"
                ></v-text-field>
                </div>
                <div class="col-12 col-sm-3">
                <v-btn class="btn-starkoms-primary col-12" dark style="min-width: 138px;" @click="detectFilterChange" >Buscar</v-btn>
                </div>
              </div>
              </div>
              <div class="d-flex justify-space-between row">
                <div class="d-flex flex-column col-12 col-sm-3">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_calendar"></i> Filtro fecha</span>
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                    style="border-bottom: 1px solid #000;"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-bind="attrs"
                        v-on="on"
                        clearable
                        @click:clear="limpiarRango"
                        v-model="dateRangeText"
                        placeholder="Todos"
                        solo
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker id="datepicker-starkoms" @input="formatearRango" range v-model="dates"></v-date-picker>
                  </v-menu>
                </div>
                <div class="d-flex flex-column col-12 col-sm-3">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_states"></i> Filtro por estado</span>
                  <v-select
                    class="ml-2 mr-2"
                    label="Filtro por estado"
                    :items="orderStateList"
                    v-model="selectOrderState"
                    item-text="name"
                    v-on:change="detectFilterChange"
                    solo
                  ></v-select>
                </div>
                <div class="d-flex flex-column col-12 col-sm-3">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-filter_types"></i> Filtro por tipo</span>
                  <v-select
                    class="ml-2 mr-2"
                    label="Filtro por tipo"
                    :items="orderTypeList"
                    v-model="selectTypeList"
                    item-text="text"
                    item-value="code"
                    v-on:change="detectFilterChange"
                    solo
                  ></v-select>
                </div>
                <!-- <div class="d-flex flex-column">
                  <span class="text-h6 font-weight-regular black--text"><i class="icon-warehouses"></i> Filtro por bodega</span>
                  <v-select
                    class="ml-2 mr-2"
                    label="Filtro por bodega"
                    :items="warehousesList"
                    v-model="selectWarehouses"
                    item-text="name"
                    @input="fetchDataPaginated"
                    solo
                  ></v-select>
                </div> -->
                <div class="d-flex flex-column source_filter col-12 col-sm-3">
                  <span class="text-h6 font-weight-regular black--text "><v-icon>mdi-store</v-icon> Filtro por canal</span>
                  <v-autocomplete
                        class="ml-2 mr-2"
                        v-model="selectSources"
                        :items="sourcesList"
                        chips
                        clearable
                        deletable-chips
                        label=""
                        multiple
                        item-text="name"
                        item-value="id"
                        v-on:change="checkSelection"
                    >
                        <template v-slot:item="data">
                            <template>
                                <v-list-item-content>
                                    <v-list-item-title v-html="data.item.name"></v-list-item-title>
                                </v-list-item-content>
                            </template>
                        </template>
                    </v-autocomplete>

                </div>
                <div class="d-flex">
                  <v-btn
                    v-show="!!search || !!dateRangeText || selectOrderState != 'Todos' || selectTypeList != 'Todos' || selectWarehouses != 'Todos' || selectSources.length != 0 "
                    @click="limpiarFiltros"
                    class="align-self-center" dark color="grey"
                  >Limpiar Filtros</v-btn>
                </div>
              </div>
              <v-row v-if="(showSelect || selected.length > 0) || added.length">
                <v-col>
                  <v-card class="d-flex">
                    <v-card-text>
                      <span class="subheading">Cola de Pedidos ({{added.length}})</span>
                      <v-chip-group column show-arrows >
                        <v-chip
                          v-for="(orderSelected,index) in added"
                          :key="index"
                          close
                          @click:close="added.splice(index, 1)"
                        >
                          {{ orderSelected.order_reference }}
                        </v-chip>
                      </v-chip-group>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-text>
          <v-data-table
            :headers="headers"
            :items="tableData"
            :single-select="false"
            :show-select="showSelect"
            v-model="selected"
            no-results-text="No hay resultados disponibles"
            no-data-text="No hay datos disponibles"
            loading-text="Cargando datos..."
            :sort-by="'id'"
            :sort-desc="true"
            class="elevation-1 datatable"
            :disable-sort="true"
            :loading="dataTableLoading"
            :disable-pagination="true"
            :hide-default-footer="true"
            :items-per-page="selectItemsPerPage"
          >
            <template v-slot:item.det="{ item }">
              
            <v-tooltip top v-if="item.order_type_code == 'OD'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-light mb-1"><i class="fa fa-play fa-fw"></i></span>
                </template>
                <span>{{item.order_type}}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.order_type_code == 'OR'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-light mb-1"><i class="fa fa-forward fa-fw"></i></span>
                </template>
                <span>{{item.order_type}}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.order_type_code == 'PS'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-light mb-1"><i class="fa fa-building fa-fw"></i></span>
                </template>
                <span>{{item.order_type}}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.order_type_code == 'OA'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-light mb-1"><i class="fa fa-cart-arrow-down  fa-fw"></i></span>
                </template>
                <span>{{item.order_type}}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.order_type_code == 'OV'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-light mb-1"><i class="fa fa-cloud  fa-fw"></i></span>
                </template>
                <span>{{item.order_type}}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.order_type_code == 'OS'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-light mb-1"><i class="fa fa-retweet fa-fw"></i></span>
                </template>
                <span>{{item.order_type}}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.order_type_code == 'ODLI'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-light mb-1"><i class="fa fa-step-forward"></i></span>
                </template>
                <span>{{item.order_type}}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.order_type_code == 'ODB2B'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-light mb-1"><i class="fa fa-arrows-alt"></i></span>
                </template>
                <span>{{item.order_type}}</span>
            </v-tooltip>
            <v-tooltip top v-if="item.order_type_code == 'PUDC'">
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-light mb-1"><i class="fa fa-building fa-fw"></i></span>
                </template>
                <span>{{item.order_type}}</span>
            </v-tooltip>
            <v-tooltip top  v-if="item.tags != null" >
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-danger mb-1"><i class="fa fa-tags"></i></span>
                </template>
                <span>{{item.tags}}</span>
            </v-tooltip>

            <v-tooltip top  v-if="item.order_comments != null && item.order_comments.length > 0" >
                <template v-slot:activator="{ on }">
                  <span v-on="on" class="badge badge-circle badge-warning mb-1"><i class="fa fa-comments"></i></span>
                </template>
                <span>{{item.order_comments.length}} Comentario(s)</span>
            </v-tooltip>
           
            </template>

            <template v-slot:item.id="{ item }">
              <td>

                <span>{{ item.id }}</span>
              </td>
            </template>
            <template v-slot:item.order_reference="{ item }">
              <td>
                  <v-tooltip top  v-if="item.order_reference.length > 20" >
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class=""><span> <a class="text-decoration-none" @click="$router.push({name: 'orden', params: {orden: item.id}})">{{item.order_reference.substring(0, 17) + '...'}}</a> </span></span>
                    </template>
                     <span> {{item.order_reference}}</span>
                  </v-tooltip>
                  <a v-else class="text-decoration-none" @click="$router.push({name: 'orden', params: {orden: item.id}})">{{item.order_reference}}</a>
              </td>
            </template>

            <template v-slot:item.email="{ item }">
              <td class="text-left">

                   <v-tooltip top  v-if="item.email.length > 18" >
                    <template v-slot:activator="{ on }">
                      <span v-on="on" class=""><span>{{item.email.substring(0, 15) + '...'}}</span></span>
                    </template>
                    <span>{{item.email}} </span>
                  </v-tooltip>

                
                <span v-else>{{ item.email }}</span>
              </td>
            </template>

            <template v-slot:item.shipping_link="{ item }">
              <td class="text-center">
                <a v-if="item.tracking_carrier" :href="'#'" @click="getShippingLink(item.tracking_carrier)" >{{ item.shipping_number }}</a>
                <span v-if="!item.tracking_carrier" >{{ item.shipping_number }}</span>
              </td>
            </template>
            <template v-slot:item.created_at="{ item }">
              <td
                class="text-center"
              >{{$moment(item.created_at).format('LLLL')}}</td>
            </template>
            <template v-slot:item.name_order_state="{ item }">
              <td class="d-flex flex-column">
                <button
                  :class="'btn btn-sm btn-'+item.colour_order_state"
                  v-text="item.name_order_state+(item.name_order_sub_state ? ' ('+item.name_order_sub_state+')' : '')"
                ></button>
              </td>
            </template>
            <template v-slot:item.acciones="{ item }">
              <td class="d-flex">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <i
                      style="font-size: 1.5rem !important;"
                      class="icon-action_quickview mr-2"
                      v-on="on"
                      @click="verOrden(item.id)"
                    ></i>
                  </template>
                  <span>Vista previa</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <i
                      
                      style="font-size: 1.5rem !important;"
                      class="icon-action_editorder mr-2"
                      v-on="on"
                      @click="$router.push({name: 'orden', params: {orden: item.id}})"
                    ></i>
                  </template>
                  <span>Ver/Editar orden</span>
                </v-tooltip>
                <v-tooltip bottom v-if="!['DEFAULT_CANCELED','DEFAULT_IN_CUSTOMER','DEFAULT_REDELIVERY'].includes(item.type_order_state) || editOrderDetailPermission">
                  <template v-slot:activator="{ on }">
                    <i
                      style="font-size: 1.5rem !important;"
                      class="icon-action_updatestate mr-2"
                      v-on="on"
                      @click="cambiarEstado({order_id: item.id, store_id: item.store_id, current_state: item.current_state, current_substate_name: item.current_sub_state})"
                    ></i>
                  </template>
                  <span>Cambiar estado</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.type_order_state == 'DEFAULT_IN_LOGISTIC'">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      color="#8c8c8c"
                      class="mr-2"
                      v-on="on"
                      @click="generarEtiqueta(item)"
                    >flaticon2-tag</v-icon>
                  </template>
                  <span>Generar etiqueta</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-icon
                      style="font-size: 1.5rem !important"
                      small
                      color="#8c8c8c"
                      class="mr-2"
                      v-on="on"
                      @click="softDelete(item)"
                    >mdi-package-down</v-icon>
                  </template>
                  <span>Archivar</span>
                </v-tooltip>

                <v-tooltip bottom v-if="item.tracking_url"> 
                  <template v-slot:activator="{ on }">
                    <v-icon
                      style="font-size: 1.5rem !important;"
                      small
                      color="#8c8c8c"
                      class="mr-2"
                      v-on="on"
                      @click="getShippingLink(item.tracking_url)"
                    >mdi-truck-delivery</v-icon>
                  </template>
                  <span>Link Seguimiento</span>
                </v-tooltip>

              </td>
            </template>
          </v-data-table>
          <div class="d-flex justify-space-between mt-4">
            <v-select
              style="max-width: 120px"
              @input="fetchDataPaginated"
              v-model="selectItemsPerPage"
              label="Registros por página"
              :items="[10,20,50,100, 500]"
            ></v-select>
            <v-pagination
              @input="fetchDataPaginated"
              v-model="page"
              :length="totalPages"
              :total-visible="25"
              color="#e55d43"
            ></v-pagination>
          </div>
        </v-card-text>
      </v-card>
    </v-app>
    <dialog-ver-orden></dialog-ver-orden>
    <dialog-cambiar-estado-orden @success="abrirMensajeSuccess"></dialog-cambiar-estado-orden>
    <dialog-seleccionar-courier @success="abrirMensajeSuccess"></dialog-seleccionar-courier>
    <dialog-cambiar-estado-masivo @success="handleCambioEstadoMasivoSuccess"></dialog-cambiar-estado-masivo>
    <exportar-ordenes></exportar-ordenes>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import DialogVerOrden from "@/views/oms/Ordenes/DialogVerOrden";
import DialogCambiarEstadoOrden from "@/views/oms/Ordenes/DialogCambiarEstadoOrden";
import DialogSeleccionarCourier from "@/views/oms/Ordenes/DialogSeleccionarCourier";
import format from "date-fns/format";
import DialogCambiarEstadoMasivo from '@/views/oms/Ordenes/DialogCambiarEstadoMasivo';
import ExportarOrdenes from '@/views/oms/Ordenes/ExportarOrdenes';
export default {
  components: {
    DialogVerOrden,
    DialogCambiarEstadoOrden,
    DialogSeleccionarCourier,
    DialogCambiarEstadoMasivo,
    ExportarOrdenes 
  },
  data() {
    return {
      showSelect: false,
      added: [],
      selectItemsPerPage: 100,
      dataTableLoading: false,
      page: 1,
      totalPages: 1,
      itemsLength: 0,
      search: "",
      selected: [],
      dateFormat: "D MMM",
      dateOne: "",
      dateTwo: "",
      tableData: [],
      orderStateList: [],
      sourcesList: [],
      warehousesList: [],
      /*orderStateList: [
                'Todos',
                'Ingresado',
                'Generando Etiqueta',
                'Listo para Enviar',
                'Generando Cobro',
                'Cobrado',
                'Generando Factura',
                'Facturado',
                'Entregado a Courier',
                'En tránsito',
                'Entregado'
            ],*/
      orderTypeList: [],//["Todos", "OD", "OA", "OR", "PS"],
      selectOrderState: "Todos",
      selectTypeList: "Todos",
      selectWarehouses: "Todos",
      selectSources: [],
      superTableData: [],
      dates: [],
      menu1: false,
      dateRangeText: "",
      api_url: process.env.VUE_APP_API_URL,
      editOrderDetailPermission: this.$store.getters['passport/userCan']({ "name": "Modificacion ordenes", "category": "Módulo Órdenes" })
    };
  },
  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Ordenes", route: "/#/ordenes" },
    ]);
    this.fetchOrderTypes();
    this.fetchSources();
    await this.fetchOrdersStates();
    this.getParamsFromUrl();
  },
  methods: {
    getParamsFromUrl(){
      var vm = this;
      
      if(this.$route.query.search){
        vm.search = this.$route.query.search;
      }
      if(this.$route.query.date){
        vm.dateRangeText = this.$route.query.date;
      }
      if(this.$route.query.state){
        vm.selectOrderState = this.$route.query.state;
      }
      if(this.$route.query.type){
        vm.selectTypeList = this.$route.query.type;
      }
      if(this.$route.query.sources){
        vm.selectSources = JSON.parse(this.$route.query.sources);
      }
      
      this.fetchDataPaginated();
    },
    detectFilterChange(){
      var vm = this;
      let filters = {};

      if (vm.search && vm.search != "") {
        filters['search'] = vm.search;
      }
      if (vm.dateRangeText && vm.dateRangeText != "") {
        filters['date'] = vm.dateRangeText;
      }
      if (vm.selectOrderState != "Todos") {
        filters['state'] = vm.selectOrderState;
      }
      if (vm.selectTypeList != "Todos") {
        filters['type'] = vm.selectTypeList;
      }
      if (vm.selectSources.length > 0) {
        filters['sources'] = JSON.stringify(vm.selectSources);
      }
      
      this.$router.push({path: this.$route.path, query: filters });
      this.fetchDataPaginated();
    },
    checkSelection(){
      var vm = this;
      vm.selectSources.forEach(elem => {
        if(Array.isArray(elem)){
          vm.selectSources.pop();
          var isRemove = true;
          elem.forEach(inelem => {
            if(!vm.selectSources.includes(inelem)){
              console.log(inelem);
              vm.selectSources.push(inelem);
              isRemove = false;
            }
          });
          console.log(isRemove);
          if(isRemove){
            elem.forEach(inelem => {
              var index = vm.selectSources.indexOf(inelem);
              if (index !== -1) {
                  vm.selectSources.splice(index, 1);
              }
            });
          }
        }
      });
      this.detectFilterChange();
    },
    addSelected(){
      if(this.selected.length){
        if((this.selected.length + this.added.length) > 50){
          this.$bvToast.toast("La cola de pedidos tiene un limite de 50 items. Debes descartar "+((this.selected.length + this.added.length)-50)+' pedidos.', {
              title: `Alerta`,
              variant: 'warning',
              solid: true,
              toaster: 'b-toaster-bottom-center'
          });
          return;
        }
        this.selected.forEach((elem) => {
          if(!this.added.find(e => e.id == elem.id)){
            this.added.push({
              order_reference: elem.order_reference,
              id: elem.id,
              store_id: elem.store_id,
            })
          }
        });
      }
    },
    openDialogCambiarEstadoMasivo(){
      if(this.added.length > 0){
        this.$emit('openDialogCambiarEstadoMasivo', this.added );
      }
    },
    handleCambioEstadoMasivoSuccess(mensaje){
      this.added = [];
      this.$bvToast.toast(mensaje, {
        title: `Información`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-bottom-center",
      });
      this.fetchDataPaginated();
    },
    limpiarFiltros() {
      this.search = "";
      this.dateRangeText = "";
      this.selectOrderState = "Todos";
      this.selectTypeList = "Todos";
      this.selectWarehouses = "Todos";
      this.selectSources = [];
      this.detectFilterChange();
    },
    desactivaKeydown() {
      console.log("disable keydown");
    },
    fetchDataPaginated(params = null) {
        this.dataTableLoading = true;
        var vm = this;
        const sortBy = "id";
        const sortDesc = true;
        const page = this.page;
        const itemsPerPage = this.selectItemsPerPage;
        const search = this.search;
        const filterState = this.orderStateList.find(e => e.name == this.selectOrderState) ? this.orderStateList.find(e => e.name == this.selectOrderState).id : null;
        const filterSources = this.selectSources;
        const filterWarehouse = this.warehousesList.find(e => e.name == this.selectWarehouses) ? this.warehousesList.find(e => e.name == this.selectWarehouses).id : null;
        const filterType = this.selectTypeList != 'Todos' && this.selectTypeList ? this.selectTypeList : null;
        const startDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[0];
        const endDate = !(this.dateRangeText.split(' ~ ').length == 2) ? null : this.dateRangeText.split(' ~ ')[1];
        const timezoneUser = localStorage.getItem('timezone') ? localStorage.getItem('timezone') : 'America/Santiago';
        this.axios({
        url: "orders/paginated/json",
        method: "GET",
        params: {
          page,
          perPage: itemsPerPage,
          sortBy: sortBy,
          sortDesc: sortDesc ? 1 : 0,
          search,
          filterState,
          filterSources,
          filterType,
          filterWarehouse,
          startDate,
          endDate,
          timezoneUser
        },
      })
        .then((response) => {
          let arreglo = [];
          if (response.data.data instanceof Object) {
            Object.keys(response.data.data).forEach((key) => {
              arreglo.push(response.data.data[key]);
            });
            vm.tableData = arreglo;
          } else {
            vm.tableData = response.data.data.map((e) => e);
          }
          vm.totalPages = response.data.last_page;
          vm.dataTableLoading = false;
          vm.selected = [];
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    async fetchOrdersStates() {
        var vm = this;

        await this.axios({
            url: "orders/order_states/data_select_input",
            method: "GET",
        })
        .then((response) => {
          vm.orderStateList = response.data.data;
          vm.orderStateList.unshift({ id: null, name: "Todos", order: null });
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
          this.fetchDataPaginated();
        });
    },
    fetchSources() {
        var vm = this;

        this.axios({
            url: "admin/stores/default/marketplaces_data",
            method: "GET",
        })
        .then((response) => {
          var mapeado = [];
          var datos = response.data;
          mapeado.push({ id: "Manual", name: "Manual" });
          datos.forEach((item) => {
            mapeado.push({
              id: item.marketplace.name,
              name: item.marketplace.name,
            });
          });
          vm.sourcesList = mapeado;
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    fetchWarehouses() {
        var vm = this;
        this.axios({
            url: "orders/warehouses/data_select_input",
            method: "GET",
        })
        .then((response) => {
          console.log("fetchWarehouses response.data.data", response.data.data);
          vm.warehousesList = response.data.data;
          vm.warehousesList.unshift({ id: null, name: "Todos", order: null });
        })
        .catch((error) => {
          console.log(error);
          vm.dataTableLoading = false;
        });
    },
    fetchOrderTypes() {
      var vm = this;
      this.axios({
          url: "orders/order_types/data_select_input",
          method: "GET",
      })
      .then((response) => {
        vm.orderTypeList = response.data;
        vm.orderTypeList.unshift({ value: "Todos", text: "Todos", code: "Todos" });;
      })
      .catch((error) => {
        console.log(error);
        vm.dataTableLoading = false;
      });
    },
    verOrden(orden_id) {
      this.$emit("openDialogVerOrden", orden_id);
    },
    cambiarEstado(datos) {
      this.$emit("openDialogCambiarEstadoOrden", datos);
      console.log(datos);
    },
    abrirMensajeSuccess(mensaje) {
      this.$bvToast.toast(mensaje, {
        title: `Información`,
        variant: "success",
        solid: true,
        toaster: "b-toaster-bottom-center",
      });
      this.fetchDataPaginated();
    },
    generarEtiqueta(datos) {
      this.$emit("openDialogSeleccionarCourier", datos);
      console.log("metodo generar etiqueta", datos);
    },
    filtroRangoFechas(value) {
      if (!this.dateRangeText || !this.dates.length) {
        return true;
      } else {
        if (
          this.$moment(value).isSameOrAfter(
            this.$moment(this.dates[0])
          ) &&
          this.$moment(value).isSameOrBefore(
            this.$moment(this.dates[1])
          )
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = "";
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat);
      }
      if (dateTwo) {
        formattedDates += " - " + format(dateTwo, this.dateFormat);
      }
      return formattedDates;
    },
    softDelete(item) {
      if(item.type_order_state != 'DEFAULT_CANCELED'){
        this.cambiarEstado({order_id: item.id, store_id: item.store_id, current_state: item.current_state, current_substate_name: item.current_sub_state, archive: true});
      }
      else if(confirm("Está seguro?")) {
        var vm = this;
        this.axios({
          url: "orders/" + item.id,
          method: "DELETE",
        })
          .then((response) => {
            vm.abrirMensajeSuccess("Pedido archivado correctamente");
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    formatearRango() {
      if (!this.dates.length) {
        this.dateRangeText = "";
      } else {
        this.dateRangeText = this.dates.join(" ~ ");
      }
      this.detectFilterChange();
    },
    limpiarRango() {
      this.dates = [];
      this.dateRangeText = "";
      this.detectFilterChange();
    },
    getShippingLink(url)
    {
      window.open(url, '_blank');
      /*this.axios({
        url: 'shipment/shipping_link?order_reference='+item.order_reference+'&store='+item.store_id+'&source='+item.source_id,
      }).then( response => {
        window.open(response.data, '_blank');
      })*/
    },
    getStakOmsTrackingLink(item)
    {
      this.axios({
        url: 'orders/'+item.id+'/generate/tracking',
      }).then( response => {
        window.open(response.data, '_blank');
      })
    },
    exportExcelAll() {
      var vm = this;
      this.axios({
        url: "orders/json",
        method: "GET",
      })
        .then((response) => {
          let headers = [
            "id",
            "type",
            "reference",
            "tracking",
            "marketplace",
            "date",
            "estado",
            "sku",
            "name",
            "quantity",
            "total",
          ];
          let mapeado = [];
          let datos = response.data.data;
          datos.forEach((order) => {
            if (order.order_details.length) {
              order.order_details.forEach((product) => {
                mapeado.push({
                  id: order.id,
                  type: order.order_type,
                  reference: order.order_reference,
                  tracking: order.shipping_number,
                  marketplace: order.source,
                  date: order.source_date,
                  estado: order.name_order_state,
                  sku: product.sku,
                  name: product.product,
                  quantity: product.quantity,
                  total: product.total,
                });
              });
            }
          });
          vm.axios({
            url: "excel/from_array",
            method: "POST",
            data: {
              data: mapeado,
              cabeceras: headers,
            },
            responseType: "blob",
          })
            .then((response) => {
              const newBlob = new Blob([response.data], {
                type:
                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
              });
              const data = window.URL.createObjectURL(newBlob);
              window.open(data, "_blank");
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    },
    exportExcel() {
      var vm = this;
      var headers = [
        "id",
        "type",
        "reference",
        "tracking",
        "marketplace",
        "shipping",
        "date",
        "estado",
        "sku",
        "name",
        "quantity",
        "total",
      ];
      var mapeado = [];
      this.tableData.forEach((order) => {
        if (order.order_details.length) {
          order.order_details.forEach((product) => {
            mapeado.push({
              id: order.id,
              type: order.order_type,
              reference: order.order_reference,
              tracking: order.shipping_number,
              marketplace: order.source,
              shipping: order.shipping_number,
              date: order.source_date,
              estado: order.name_order_state,
              sku: product.sku,
              name: product.product,
              quantity: product.quantity,
              total: product.total,
            });
          });
        }
      });
      this.axios({
        url: "excel/from_array",
        method: "POST",
        data: {
          data: mapeado,
          cabeceras: headers,
        },
        responseType: "blob",
      })
        .then((response) => {
          const newBlob = new Blob([response.data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;",
          });
          const data = window.URL.createObjectURL(newBlob);
          window.open(data, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  computed: {
    headers() {
      return [
        { text: "", value: "det", sortable: false, width:'50px'},
        { text: "# de orden", align: "left", sortable: false, value: "id", width: '100px' },
        { text: "Referencia", value: "order_reference" /*, filter: v => (v+'').toLowerCase().includes(this['search'].toLowerCase())*/,},
        // { text: "Tipo", value: "order_type_code",
          /*
          filter: (v) => {
            return this["selectTypeList"] == "Todos"
              ? true
              : v == this["selectTypeList"];
          },
          */
        // },
        { text: "Email", value: "email" },
        { text: "Origen", value: "source" },
        { text: "Courier", value: "carrier"},
        { text: "Tracking", value: "shipping_link"},
        {  text: "Fecha", value: "created_at", width: '120px'
          /*
          filter: (v) => this["filtroRangoFechas"](v),
          */
        },
        { text: "Estado", value: "name_order_state", width: '180px',
          /*
          filter: (v) => {
            return this["selectOrderState"] == "Todos"
              ? true
              : v == this["selectOrderState"];
          },
          */
        },
        { text: "Acciones", value: "acciones", sortable: false },
      ];
    },
  },
};
</script>

<style>
.dashboard_ordenes #icono-color-gris::before,
.dashboard_ordenes .icon-warehouses:before{
  color: #8c8c8c !important;
}
.blue_tag{
  color: #5867dd !important;
}
.source_filter .v-autocomplete.v-input > .v-input__control > .v-input__slot {
  background-color: #f2f2f2 !important;
  padding: 0px 12px;
  border-radius: 4px !important;
}
.source_filter .v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}
.source_filter .theme--light.v-label {
  color: black;
}
.source_filter .v-select__slot{
  margin-top: 8px;
}
.source_filter .theme--light.v-text-field > .v-input__control > .v-input__slot:before{
  border-color: rgba(0, 0, 0, 0);
}
.datatable table .btn {
    /* word-break: break-word; */
    max-width: 180px;
}
</style>