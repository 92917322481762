<template>
<v-app>
    <v-dialog v-model="showDialog" max-width="1000px">
        <v-card v-if="showDialog">
            <v-card-title>
                <span>Cambiar estado a Cola de pedidos ({{ordersQueue.length}})</span>
            </v-card-title>
            <v-card-text>
                <v-row>
                    <v-col>
                        <v-chip-group column show-arrows >
                            <v-chip
                            v-for="(orderSelected,index) in ordersQueue"
                            :key="index"
                            >
                            {{ orderSelected.order_reference }}
                            </v-chip>
                        </v-chip-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-select
                            v-model="current_state"
                            :items="orderStatesList"
                            :rules="[v => !!v || 'Debes elegir un estado']"
                            label="Nuevo estado a aplicar"
                            required
                        ></v-select>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    color="primary"
                    text
                    @click="showDialog = false"
                    >
                    Cancelar
                </v-btn>
                <v-btn
                    color="success"
                    text
                    @click="sendForm"
                    >
                    Guardar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</v-app>
</template>

<script>
export default {
    data(){
        return {
            showDialog: false,
            store_id: undefined,
            current_state: undefined,
            orderStatesList: [],
            ordersQueue: [],
        }
    },
    created(){
        
        this.$parent.$on('openDialogCambiarEstadoMasivo', (datos) => {
            if(datos.length){
                this.ordersQueue = datos;
                this.store_id = datos[0].store_id;
                // Fetch Lista de estados
                this.fetchData(); // en fetcData se setea el showDialog = true
                //this.showDialog = true;
            }
            
        });
    },
    methods: {
        sendForm(){
            if(this.current_state){
                var vm = this;
                this.axios({
                    url: 'orders/order/bulk-update-state',
                    method: 'POST',
                    data: {
                        orders: this.ordersQueue.map(e => e.id),
                        state: this.current_state
                    }
                }).then( () => {
                    vm.showDialog = false;
                    vm.$emit('success', "En los próximos segundos se actualizarán los estados de las órdenes seleccionadas");
                }).catch( () => {
                    //console.log(error);
                });
            }
        },
        fetchData(){
            var vm = this;
            this.axios({
                url: 'orders/bulk-update-states/data_select_input'
            }).then( response => {
                vm.orderStatesList = response.data;
                vm.showDialog = true; 
            }).catch( (error) => {
                console.log(error);
            });
        }
    }
}
</script>